import { FC } from 'react';

import { GlobalTemplate } from '@/components/3_template/GlobalTemplate';
import { ConfiguredHead } from '@/components/0_atom/ConfiguredHead';
import { HomePageSection1 } from '@/components/4_page/HomePage/Section1';
import { HomePageSection2 } from '@/components/4_page/HomePage/Section2';
import { HomePageSection3 } from '@/components/4_page/HomePage/Section3';
import { HomePageSection4 } from '@/components/4_page/HomePage/Section4';

const HomePage: FC = () => {
    return (
        <GlobalTemplate>
            <ConfiguredHead
                title={'電話番号と銀行口座で、かんたんにキャッシュバックをゲット！'}
                asPath={'/'}
                ogType={'website'}
                description={
                    'moneybackは、電話番号と銀行口座を登録するだけでキャッシュバックキャンペーンに参加できるサイトです。キャッシュバックの条件を満たしたら自動的に振り込み。受け取り手続きは一切必要ありません。'
                }
                isIndexPage
            />
            <main>
                <HomePageSection1 />
                <HomePageSection2 />
                <HomePageSection3 />
                <HomePageSection4 />
            </main>
        </GlobalTemplate>
    );
};

HomePage.displayName = 'HomePage';
export default HomePage;
