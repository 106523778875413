import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.4194 0.188767L6.50116 15.2286L1.58056 9.91153C1.34764 9.65984 0.969978 9.65984 0.737008 9.91153L0.17469 10.5192C-0.05823 10.7709 -0.05823 11.1789 0.17469 11.4307L6.0794 17.8112C6.31232 18.0629 6.68999 18.0629 6.92296 17.8112L21.8253 1.70798C22.0582 1.45629 22.0582 1.0482 21.8253 0.796453L21.2629 0.188767C21.03 -0.0629224 20.6524 -0.0629224 20.4194 0.188767Z"
                fill="url(#paint0_linear)"
            />
            <defs>
                <linearGradient id="paint0_linear" x1="5.5" y1="11" x2="22" y2="11" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4EE5DC" />
                    <stop offset="1" stopColor="#F746FB" />
                </linearGradient>
            </defs>
        </svg>
    );
};
Svg.displayName = 'svg/check-brandcolor';
export default Svg;
