import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.8571 8.25001H16.4286V6.24767C16.4286 2.82736 13.5714 0.00861361 10.0179 1.98702e-05C6.46429 -0.00857387 3.57143 2.7715 3.57143 6.18751V8.25001H2.14286C0.959821 8.25001 0 9.17384 0 10.3125V19.9375C0 21.0762 0.959821 22 2.14286 22H17.8571C19.0402 22 20 21.0762 20 19.9375V10.3125C20 9.17384 19.0402 8.25001 17.8571 8.25001ZM5.71429 6.18751C5.71429 3.91447 7.63839 2.06252 10 2.06252C12.3616 2.06252 14.2857 3.91447 14.2857 6.18751V8.25001H5.71429V6.18751ZM17.8571 19.9375H2.14286V10.3125H17.8571V19.9375Z"
                fill="url(#paint0_linear)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="0"
                    y1="0"
                    x2="19.1389"
                    y2="22.7252"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4EE5DC" />
                    <stop offset="1" stopColor="#F245F6" />
                </linearGradient>
            </defs>
        </svg>
    );
};
Svg.displayName = 'svg/lock-brand';
export default Svg;
