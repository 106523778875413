import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { HomePageSection2Block } from '@/components/4_page/HomePage/Section2/Block';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 127,
    },
}));

export const HomePageSection2: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <section className={c.root}>
            <HomePageSection2Block
                title={'登録は電話番号と銀行口座だけ！'}
                description={
                    '電話番号と銀行口座の登録だけでキャッシュバックキャンペーンに参加できます。面倒な登録手続きはありません！'
                }
            />
            <HomePageSection2Block
                title={'自動で振り込み！'}
                description={
                    '条件を満たすと、キャッシュバックは自動的に銀行口座に振り込まれます。出金申請はいりません。また、振り込み手数料はかかりません。'
                }
            />
        </section>
    );
};
HomePageSection2.displayName = 'HomePageSection2';
