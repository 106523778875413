import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import SvgBrandIcon from '@/svgs/moneyback_icon';

const useStyles = makeStyles((theme) => ({
    root: {},
    brand: {
        marginTop: 160,
        width: 106,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
    },
    slogan: {
        fontSize: 18,
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        marginTop: 56,
    },
    sloganInner: {
        '& > div:first-child': {
            display: 'flex',
            marginBottom: 6,
            lineHeight: '1.8',
            '& > span:nth-child(2)': {
                marginLeft: 4,
            },
        },
    },
    cashback: {
        display: 'inline',
        '& hr': {
            margin: 0,
            width: '100%',
            border: 'none',
            background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            height: 2,
        },
    },
}));

export const HomePageSection1: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <section className={c.root}>
            <SvgBrandIcon className={c.brand} />
            <div className={c.slogan}>
                <div className={c.sloganInner}>
                    <div>
                        <div className={c.cashback}>
                            {'キャッシュバック'}
                            <hr />
                        </div>
                        <span>{'を'}</span>
                    </div>
                    <span>{'かんたんに'}</span>
                </div>
            </div>
        </section>
    );
};
HomePageSection1.displayName = 'HomePageSection1';
