import { FC, ReactNode } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        padding: '35px 32px',
        borderRadius: 23,
        '&:not(:first-child)': {
            marginTop: 22,
        },
    },
    number: {
        background: 'linear-gradient(203deg, #4EE5DC, #F746FB)',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        fontWeight: 600,
        margin: '0 auto',
        width: 34,
        height: 34,
    },
    title: {
        textAlign: 'center',
        fontSize: 18,
        margin: '32px 0 0 0',
    },
    children: {
        margin: '45px 0 0 0',
    },
}));

export const HomePageSection3Block: FC<{
    number: string;
    title: string;
    children: ReactNode;
}> = ({ number, title, children }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <section className={c.root}>
            <span className={c.number}>{number}</span>
            <h3 className={c.title}>{title}</h3>
            <div className={c.children}>{children}</div>
        </section>
    );
};
HomePageSection3Block.displayName = 'HomePageSection3Block';
