import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import SvgCheckCircle from '@/svgs/check-brand-circle';

const useStyles = makeStyles((theme) => ({
    root: {
        '& svg': {
            margin: '0 auto',
            display: 'block',
            width: 39,
            height: 39,
        },
        '&:not(:first-child)': {
            marginTop: 51,
        },
    },
    headingBox: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 23,
    },
    headingBoxInner: {
        '& h3': {
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 600,
            textAlign: 'center',
            padding: '0 2em',
        },
        '& hr': {
            margin: 0,
            width: '100%',
            border: 'none',
            background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            height: 2,
        },
    },
    description: {
        margin: '18px auto 0 auto',
        // padding: '0 14px',
        maxWidth: 360,
    },
}));

export const HomePageSection2Block: FC<{
    title: string;
    description: string;
}> = ({ title, description }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <section className={c.root}>
            <SvgCheckCircle />
            <div className={c.headingBox}>
                <div className={c.headingBoxInner}>
                    <h3>{title}</h3>
                    <hr />
                </div>
            </div>
            <p className={c.description}>{description}</p>
        </section>
    );
};
HomePageSection2Block.displayName = 'HomePageSection2Block';
