import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CampaignProcedure } from '@/components/2_org/CampaignProcedure';
import { HomePageSection3Block } from '@/components/4_page/HomePage/Section3/Block';
import SvgLock from '@/svgs/lock-brand';
import SvgUnlock from '@/svgs/unlock-brand';

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'linear-gradient(92deg, #4EE5DC, #F746FB)',
        paddingTop: 39,
        paddingBottom: 39,
        marginTop: 120,
    },
    heading: {
        fontSize: 24,
        textAlign: 'center',
        color: '#fff',
        marginTop: 0,
        marginBottom: 39,
    },
    description: {
        margin: '0 0 39px 0',
    },
    step1Section: {
        '& h3': {
            display: 'flex',
            alignItems: 'center',
            lineHeight: '1',
            '& svg': {
                width: 20,
            },
            '& span': {
                display: 'block',
                marginLeft: 14,
            },
        },
        '& p': {
            paddingLeft: 34,
            '& a': {
                color: '#0a7',
                textDecoration: 'underline',
                fontWeight: 600,
            },
        },
        '&:not(:first-child)': {
            marginTop: 22,
        },
    },
}));

export const HomePageSection3: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const isDesktop = useMediaQuery('(min-width:700px)');

    return (
        <section
            className={c.root}
            style={{
                marginLeft: isDesktop ? 'calc(279px - 50vw)' : -22,
                marginRight: isDesktop ? 'calc(279px - 50vw)' : -22,
                paddingLeft: isDesktop ? 'calc(50vw - 279px)' : 8,
                paddingRight: isDesktop ? 'calc(50vw - 279px)' : 8,
            }}
        >
            <h2 className={c.heading}>{'利用方法'}</h2>
            <HomePageSection3Block title={'キャンペーンをみつけよう'} number={'1'}>
                <p className={c.description}>
                    {'キャッシュバックキャンペーンには、公開キャンペーンと非公開キャンペーンがあります。'}
                </p>
                <section className={c.step1Section}>
                    <h3>
                        <SvgUnlock />
                        <span>{'公開キャンペーン'}</span>
                    </h3>
                    <p>
                        {'公開キャンペーンは、'}
                        <a href={'/campaigns'}>{'キャンペーン一覧'}</a>
                        {'から探すことができます。'}
                    </p>
                </section>
                <section className={c.step1Section}>
                    <h3>
                        <SvgLock />
                        <span>{'限定公開キャンペーン'}</span>
                    </h3>
                    <p>
                        {
                            '限定公開キャンペーンはキャンペーン一覧から探すことはできません。見つけるには、リンクを知っている人に教えてもらう必要があります。'
                        }
                    </p>
                </section>
            </HomePageSection3Block>
            <HomePageSection3Block title={'キャンペーンに参加'} number={'2'}>
                <p className={c.description}>
                    {'キャンペーンを見つけたら、電話番号と銀行口座を入力するだけで簡単に参加できます。'}
                </p>
                <CampaignProcedure />
            </HomePageSection3Block>
        </section>
    );
};
HomePageSection3.displayName = 'HomePageSection3';
