import { FC } from 'react';
import Head from 'next/head';
import { getEnvVariables } from '@/utils/envVariables';

export const ConfiguredHead: FC<{
    title: string;
    isIndexPage?: boolean;
    description?: string;
    asPath: string;
    canonical?: string;
    ogpSrc?: string;
    ogType: 'website' | 'article';
    isNoindex?: boolean;
}> = ({ title, isIndexPage, description, asPath, canonical, ogpSrc, ogType, isNoindex }) => {
    // DATA
    const { rootUrl } = getEnvVariables();
    const appName = 'moneyback';
    const displayTitle = isIndexPage ? `${appName} | ${title}` : `${title} | ${appName}`;

    return (
        <Head>
            <title>{displayTitle}</title>
            <meta name="title" content={displayTitle} />
            <meta property="og:title" content={displayTitle} />
            <meta property="og:url" content={rootUrl + asPath} />
            {description && <meta name="description" content={description} />}
            {description && <meta name="og:description" content={description} />}
            <link rel="canonical" href={canonical ? rootUrl + canonical : rootUrl + asPath} />
            <meta property="og:site_name" content={appName} />
            <meta property="og:type" content={ogType}></meta>
            {ogpSrc && <meta name="og:image" content={ogpSrc} />}
            {isNoindex && <meta name="robots" content="noindex" />}
        </Head>
    );
};
ConfiguredHead.displayName = 'ConfiguredHead';
export default ConfiguredHead;
