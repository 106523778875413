import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '&:not(:last-child)': {
            marginBottom: 19,
        },
    },
    index: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 34,
        height: 34,
        flexShrink: 0,
        marginRight: 17,
        padding: 6,
        fontWeight: 600,
        borderRadius: '50%',
        '& svg': {
            maxWidth: '100%',
            maxHeight: '100%',
            width: 22,
            height: 22,
        },
    },
    content: {},
    contentTitle: {
        fontWeight: 600,
        display: 'block',
    },
    contentDescription: {
        display: 'block',
        // color: 'rgba(0,0,0,.55)',
        marginTop: 15,
        '& a': {
            color: '#0a7',
            fontWeight: 600,
            textDecoration: 'underline',
        },
    },
}));

export const EmphasizedNote: FC<{
    index: ReactNode;
    indexClassName?: string;
    title: string;
    descrition?: ReactNode;
}> = ({ index, indexClassName, title, descrition }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS

    return (
        <div className={c.root}>
            <span className={clsx(c.index, indexClassName)}>{index}</span>
            <span className={c.content}>
                <span className={c.contentTitle}>{title}</span>
                {descrition && <span className={c.contentDescription}>{descrition}</span>}
            </span>
        </div>
    );
};
EmphasizedNote.displayName = 'EmphasizedNote';
