import { FC } from 'react';

import HomePage from '@/components/4_page/HomePage';

const Page: FC = () => {
    return <HomePage />;
};

Page.displayName = 'pages/';
export default Page;
