import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.4194 11.1888L15.5012 26.2286L10.5806 20.9115C10.3476 20.6598 9.96998 20.6598 9.73701 20.9115L9.17469 21.5192C8.94177 21.7709 8.94177 22.1789 9.17469 22.4307L15.0794 28.8112C15.3123 29.0629 15.69 29.0629 15.923 28.8112L30.8253 12.708C31.0582 12.4563 31.0582 12.0482 30.8253 11.7965L30.2629 11.1888C30.03 10.9371 29.6524 10.9371 29.4194 11.1888Z"
                fill="url(#paint0_linear)"
            />
            <circle cx="19.5" cy="19.5" r="18.5" stroke="url(#paint1_linear)" strokeWidth="2" />
            <defs>
                <linearGradient id="paint0_linear" x1="31" y1="13.5" x2="9" y2="20" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4EE5DC" />
                    <stop offset="1" stopColor="#F746FB" />
                </linearGradient>
                <linearGradient id="paint1_linear" x1="0" y1="0" x2="39" y2="28.6765" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F245F6" />
                    <stop offset="1" stopColor="#4EE5DC" />
                </linearGradient>
            </defs>
        </svg>
    );
};
Svg.displayName = 'svg/check-brand-circle';
export default Svg;
