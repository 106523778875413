import { FC } from 'react';

const Svg: FC<{ className?: string }> = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.8571 10.3125H5.71429V6.23908C5.71429 3.97033 7.59375 2.084 9.95536 2.06252C12.3393 2.03674 14.2857 3.89728 14.2857 6.18751V7.21876C14.2857 7.79025 14.7634 8.25001 15.3571 8.25001C15.9509 8.25001 16.4286 7.79025 16.4286 7.21876V6.24767C16.4286 2.82736 13.5714 0.00861361 10.0134 1.98702e-05C6.46429 -0.00857387 3.57143 2.7715 3.57143 6.18751V10.3125H2.14286C0.959821 10.3125 0 11.2363 0 12.375V19.9375C0 21.0762 0.959821 22 2.14286 22H17.8571C19.0402 22 20 21.0762 20 19.9375V12.375C20 11.2363 19.0402 10.3125 17.8571 10.3125ZM17.8571 19.9375H2.14286V12.375H17.8571V19.9375Z"
                fill="url(#paint0_linear)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="0"
                    y1="0"
                    x2="19.1389"
                    y2="22.7252"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4EE5DC" />
                    <stop offset="1" stopColor="#F245F6" />
                </linearGradient>
            </defs>
        </svg>
    );
};
Svg.displayName = 'svg/unlock-brand';
export default Svg;
