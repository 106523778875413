import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { EmphasizedNote } from '@/components/1_mol/EmphasizedNote';
import SvgCheck from '@/svgs/check-brandcolor';

const useStyles = makeStyles((theme) => ({
    root: {},
    hr: {
        height: 27,
        width: 2,
        border: 'none',
        background: 'rgba(0,0,0,.05)',
        margin: '12px auto 16px 16px',
    },
    stepIndex: {
        background: theme.palette.text.primary,
        color: '#fff',
    },
}));

export const CampaignProcedure: FC<{
    className?: string;
    advertiserName?: string;
}> = ({ className, advertiserName }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS

    return (
        <div className={clsx(c.root, className)}>
            <div>
                <EmphasizedNote
                    index={'1'}
                    title={
                        advertiserName
                            ? 'moneybackの利用規約と、このキャンペーンの参加条件に同意します。'
                            : 'moneybackの利用規約と、キャンペーンごとの参加条件に同意します。'
                    }
                    indexClassName={c.stepIndex}
                />
                <EmphasizedNote
                    index={'2'}
                    title={'携帯電話番号を入力し、SMSに届いた確認コードを入力します。'}
                    indexClassName={c.stepIndex}
                />
                <EmphasizedNote
                    index={'3'}
                    title={'キャッシュバックを受け取る銀行口座を入力します。（後からでもOK）'}
                    indexClassName={c.stepIndex}
                />
                <EmphasizedNote
                    index={'4'}
                    title={
                        advertiserName
                            ? `${advertiserName}のページへ移動するので、そのまま手続きを進めます。`
                            : 'あとはキャンペーンごとの条件を達成するだけ！'
                    }
                    indexClassName={c.stepIndex}
                />
            </div>
            <hr className={c.hr} />
            <EmphasizedNote
                index={<SvgCheck />}
                title={'条件が達成されると、入力した銀行口座にキャッシュバックの金額が振り込まれます。'}
                descrition={
                    <>
                        <span>{'キャンペーンの状況は'}</span>
                        <a href={'/mypage/campaigns'}>{'参加済みのキャンペーン'}</a>
                        <span>{'のページからいつでも確認できます。'}</span>
                    </>
                }
            />
        </div>
    );
};
CampaignProcedure.displayName = 'CampaignProcedure';
