import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 80,
    },
    heading: {
        fontSize: 24,
        textAlign: 'center',
        marginTop: 60,
        marginBottom: 34,
    },
    description: {
        margin: 0,
        display: 'flex',
        justifyContent: 'center',
        '& p': {},
    },
    campaigns: {
        marginTop: 48,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
    },
    campaignsInner: {
        '& a': {
            padding: '.4em 1em',
            display: 'block',
        },
        '& hr': {
            margin: 0,
            width: '100%',
            border: 'none',
            background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            height: 2,
        },
    },
}));

export const HomePageSection4: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <section className={c.root}>
            <h2 className={c.heading}>{'はじめよう！'}</h2>
            <div className={c.description}>
                <p>{'まずは公開プログラムの中から、気になるプログラムを探してみましょう！'}</p>
            </div>
            <div className={c.campaigns}>
                <div className={c.campaignsInner}>
                    <a href={'/campaigns'}>{'公開プログラム一覧'}</a>
                    <hr />
                </div>
            </div>
        </section>
    );
};
HomePageSection4.displayName = 'HomePageSection4';
